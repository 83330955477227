import { useRouter } from 'next/router'
import Decimal from 'decimal.js'
import { useAtgUser } from 'frontastic/contexts/atgUserContext'

const upperRound = (number: number, fxRate: number, lcp: number, fxScale: number) => {
  let price = new Decimal(number.toString())

  const fxRateMultiplier = new Decimal(fxRate.toString())
  const lcpMultiplier = new Decimal(lcp.toString())

  price = price.mul(fxRateMultiplier)
  price = price.mul(lcpMultiplier)

  price = price.toDecimalPlaces(fxScale > 0 ? fxScale : 0, Decimal.ROUND_HALF_UP)

  return price.toFixed(2)
}

export const useBorderFreeCurrencyConversion = (price: number) => {
  const router = useRouter()
  const { e4x_auto } = router.query
  const { userSessionData } = useAtgUser()
  const priceValues: number = price
  const byPassConversion = e4x_auto && e4x_auto == 'false' ? true : false
  if (!byPassConversion && userSessionData?.dxlCurrency !== 'USD') {
    if (userSessionData?.dxlCurrency) {
      const priceData = upperRound(
        priceValues,
        userSessionData?.fxRate,
        userSessionData?.lcp,
        userSessionData?.fxScale ?? 0,
      )
      return priceData
    }
  } else {
    return priceValues.toFixed(2)
  }
}
