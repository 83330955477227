import { Box } from '@chakra-ui/react'
import { ContentstackBannerTextOnly as ContentstackBannerTextOnlyProps, BannerTextOnlyTheme } from '@Types/contentstack'
import { BannerTextOnly } from '../cms-components/banner-text-only'

export const ContentstackBannerTextOnly = ({
  overlay_image_desktop,
  overlay_image_mobile,
  overlay_image_height,
  eyebrow,
  title_text,
  banner_title_font_family,
  banner_title_font_size,
  rich_text_content,
  primary_button_label,
  primary_button_url,
  secondary_button_label,
  secondary_button_url,
  content_alignment,
  background_font_color,
  select_custom_background_color,
  title_font_color,
  select_custom_title_font_color,
  text_font_color,
  select_custom_text_color,
  theme,
  isMobileDevice,
}: ContentstackBannerTextOnlyProps) => {
  const primaryButtonProps = {
    children: primary_button_label,
    variant: 'solid',
    href: primary_button_url,
    backgroundColor: theme === 'Dark' ? 'white' : 'text',
    textColor: theme === 'Dark' ? 'text' : 'white',
    width: '100%',
    size: 'lg',
  }
  const secondaryButtonProps = {
    children: secondary_button_label,
    variant: 'outline',
    href: secondary_button_url,
    backgroundColor: theme === 'Dark' ? 'text' : 'none',
    textColor: theme === 'Dark' ? 'white' : 'text',
    borderColor: theme === 'Dark' ? 'white' : 'none',
    width: '100%',
    size: 'lg',
  }

  const richText = <>{rich_text_content && <Box dangerouslySetInnerHTML={{ __html: rich_text_content }} />}</>

  return (
    <BannerTextOnly
      isMobileDevice={isMobileDevice}
      content_alignment={content_alignment ?? null}
      text={{
        eyebrow: {
          children: eyebrow,
        },
        title: {
          font: {
            size: banner_title_font_size ?? '',
            family: banner_title_font_family ?? '',
          },
          content: {
            children: title_text,
          },
        },
        body: {
          children: richText,
        },
        ctaButtonPrimary: primaryButtonProps,
        ctaButtonSecondary: secondaryButtonProps,
      }}
      theme={theme as BannerTextOnlyTheme}
      select_custom_background_color={background_font_color === 'Custom' ? select_custom_background_color : null}
      select_custom_title_font_color={title_font_color === 'Custom' ? select_custom_title_font_color : null}
      select_custom_text_color={text_font_color === 'Custom' ? select_custom_text_color : null}
      overlay_image_desktop={overlay_image_desktop}
      overlay_image_mobile={overlay_image_mobile}
      overlay_image_height={overlay_image_height}
    />
  )
}
