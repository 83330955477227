import { MinusIcon, AddIcon } from '@chakra-ui/icons'
import {
  Accordion as AccordionChakra,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
} from '@chakra-ui/react'
import { calculateFontStyles } from 'composable/components/contentstack/utils'

export interface CmsAccordionProps {
  text?: {
    title?: {
      content?: BoxProps
      font?: {
        size?: string
        family?: string
      }
    }
    body?: BoxProps
  }
  initialStateIsOpen?: boolean
}

export const CmsAccordion = ({ initialStateIsOpen = false, text }: CmsAccordionProps) => {
  const { title, body } = text

  const {
    fontSize: _titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(title.font.size ? parseInt(title.font.size) : 400)

  let titleFont
  if (title?.font?.family === 'antonio' || title?.font?.family === 'libre-franklin') {
    titleFont = title.font.family === 'antonio' ? 'Antonio' : 'Libre Franklin'
  } else {
    titleFont = title.font.family || 'Libre Franklin'
  }

  return (
    <AccordionChakra defaultIndex={initialStateIsOpen ? 0 : null} allowToggle bg={'surface.primary'}>
      <AccordionItem margin="0px !important" padding="16px 0px !important">
        {({ isExpanded }) => (
          <>
            <AccordionButton display={'flex'} justifyContent={'space-between'} padding="0px !important">
              {title?.content?.children && (
                <Box
                  textAlign={'left'}
                  fontFamily={titleFont}
                  fontSize={_titleFontSize}
                  fontWeight={titleFontWeight}
                  lineHeight={titleLineHeight}
                  {...title?.content}
                />
              )}
              {isExpanded ? (
                <MinusIcon fontSize="13.5px" color={'primary'} />
              ) : (
                <AddIcon fontSize="13.5px" color={'primary'} />
              )}
            </AccordionButton>
            <AccordionPanel padding={0}>{body?.children && <Box tabIndex={0} {...body} />}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </AccordionChakra>
  )
}
