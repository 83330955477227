import { Box, HStack, StackProps, Text, TextProps, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { CurrencyHelpers } from 'helpers/currencyHelpers'

interface PriceProps {
  basePrice?: number
  priceProps?: TextProps
  price: string
  rootProps?: StackProps
  discountedPrice?: number
  discountPercent?: number
  discountedPriceProps?: TextProps
  promoPrice?: number
  priceRange?: any
  priceGroupQuantity?: number
  priceByEdsCodes?: any
  priority?: 'PROMO' | 'RANGE' | 'REGULAR'
}

const PromoPriceLayout = ({
  price,
  priceProps,
  promoPrice,
  discountedPrice,
  priceGroupQuantity,
  priceByEdsCodes,
  priceRange,
}) => {
  const intl = useFormat({ name: 'common' })

  return (
    <VStack gap={0} alignItems={'flex-start'}>
      {priceRange ? (
        <HStack>
          <Text as="span" textStyle={'blockquote'} color={'text'}>
            {CurrencyHelpers.formatForCurrency(priceRange?.minPrice)}
          </Text>
          <Text as="span" textStyle={'blockquote'} color={'text'}>
            {' - '}
          </Text>
          <Text as="span" textStyle={'blockquote'} color={'text'}>
            {CurrencyHelpers.formatForCurrency(priceRange?.maxPrice)}
          </Text>
        </HStack>
      ) : (
        <Text as="span" textStyle={'blockquote'} color={'text'} {...priceProps}>
          {CurrencyHelpers.formatForCurrency(price)}
        </Text>
      )}

      <Box display={'flex'} alignItems={'center'} textStyle={'body-75'}>
        <Text textStyle={'heading-desktop-75'}>{CurrencyHelpers.formatForCurrency(promoPrice || priceByEdsCodes)}</Text>
        <Text>&nbsp;{intl.formatMessage({ id: 'product.promotion.label' })}</Text>
      </Box>

      {priceGroupQuantity > 1 && (
        <Text textStyle={'body-75'} color="var(--semantic-text-danger, #C42D3A)">
          {intl.formatMessage({ id: 'product.discount.disclaimer' })}
        </Text>
      )}
    </VStack>
  )
}

const RangePriceLayout = ({ priceRange, priceByEdsCodes }) => {
  const intl = useFormat({ name: 'common' })
  return (
    <VStack gap={0} alignItems={'flex-start'}>
      <HStack>
        <Text as="span" textStyle={'blockquote'} color={'text'}>
          {CurrencyHelpers.formatForCurrency(priceRange?.minPrice)}
        </Text>
        <Text as="span" textStyle={'blockquote'} color={'text'}>
          {' - '}
        </Text>
        <Text as="span" textStyle={'blockquote'} color={'text'}>
          {CurrencyHelpers.formatForCurrency(priceRange?.maxPrice)}
        </Text>
      </HStack>

      {priceByEdsCodes && (
        <Box display={'flex'} alignItems={'center'} textStyle={'body-75'}>
          <Text textStyle={'heading-desktop-75'}>{CurrencyHelpers.formatForCurrency(priceByEdsCodes)}</Text>
          <Text>&nbsp;{intl.formatMessage({ id: 'product.promotion.label' })}</Text>
        </Box>
      )}
    </VStack>
  )
}

const PriceLayout = ({
  discountedPrice,
  discountPercent,
  priceProps,
  discountedPriceProps,
  price,
  basePrice,
  priceByEdsCodes,
}) => {
  const intl = useFormat({ name: 'common' })

  const finalOriginalPrice = discountedPrice || basePrice > price ? basePrice : null
  const finalDiscountedPrice = discountedPrice || price
  const finalDiscountPercent = finalOriginalPrice
    ? Math.floor(((finalOriginalPrice - finalDiscountedPrice) / finalOriginalPrice) * 100)
    : discountPercent
      ? discountPercent
      : null

  return (
    <VStack gap={0} alignItems={'flex-start'}>
      <HStack>
        {finalOriginalPrice && (
          <Text
            as="span"
            textStyle={'blockquote'}
            color={'var(--semantic-text-muted, #76797E)'}
            textDecoration={'line-through'}
            {...discountedPriceProps}
          >
            {CurrencyHelpers.formatForCurrency(finalOriginalPrice)}
          </Text>
        )}

        <Text as="span" textStyle={'blockquote'} color={'text'} {...priceProps}>
          {CurrencyHelpers.formatForCurrency(finalDiscountedPrice)}
        </Text>
      </HStack>

      {finalDiscountPercent && (
        <Text textStyle={'body-100'} color="danger.500">
          {intl.formatMessage({
            id: 'product.discount.label',
            values: { discountPercent: `${finalDiscountPercent}` },
          })}
        </Text>
      )}

      {priceByEdsCodes && (
        <Box display={'flex'} alignItems={'center'} textStyle={'body-75'}>
          <Text textStyle={'heading-desktop-75'}>{CurrencyHelpers.formatForCurrency(priceByEdsCodes)}</Text>
          <Text>&nbsp;{intl.formatMessage({ id: 'product.promotion.label' })}</Text>
        </Box>
      )}
    </VStack>
  )
}

enum PriceLayouts {
  PROMO,
  RANGE,
  REGULAR,
}

const defineLayout = ({ promoPrice, priceRange, priceGroupQuantity }): PriceLayouts => {
  if (promoPrice) {
    return PriceLayouts.PROMO
  }

  if (priceRange || priceGroupQuantity > 1) {
    return PriceLayouts.RANGE
  }

  return PriceLayouts.REGULAR
}

export const Price = ({
  basePrice,
  priceProps,
  price,
  rootProps,
  discountedPrice,
  discountedPriceProps,
  discountPercent,
  promoPrice,
  priceRange,
  priceGroupQuantity,
  priceByEdsCodes,
}: PriceProps) => {
  if (!price) return null

  const priceLayout = defineLayout({ promoPrice, priceRange, priceGroupQuantity })

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <HStack spacing="1" {...rootProps}>
        {priceLayout === PriceLayouts.PROMO && (
          <PromoPriceLayout
            price={price}
            priceProps={priceProps}
            promoPrice={promoPrice}
            discountedPrice={discountedPrice}
            priceGroupQuantity={priceGroupQuantity}
            priceByEdsCodes={priceByEdsCodes?.[0]}
            priceRange={priceRange}
          />
        )}

        {priceLayout === PriceLayouts.RANGE && (
          <RangePriceLayout priceRange={priceRange} priceByEdsCodes={priceByEdsCodes?.[0]} />
        )}

        {priceLayout === PriceLayouts.REGULAR && (
          <PriceLayout
            discountedPrice={discountedPrice}
            discountedPriceProps={discountedPriceProps}
            discountPercent={discountPercent}
            priceProps={priceProps}
            price={price}
            basePrice={basePrice}
            priceByEdsCodes={priceByEdsCodes?.[0]}
          />
        )}
      </HStack>
    </Box>
  )
}
