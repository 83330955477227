import React from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackBannerFull as ContentstackBannerFullProps } from '@Types/contentstack'
import { BannerFull, BannerFullProps } from '../cms-components/banner-full'
import { ObjectFit } from '../cms-components/types'
import { imageOptimization } from '../general/components/image-optimization'

export const ContentstackBannerFull = (props: ContentstackBannerFullProps) => {
  const {
    eyebrow,
    banner_title,
    title_font_color,
    select_custom_title_font_color,
    content,
    theme,
    display_opacity_overlay,
    content_alignment,
    content_positioning,
    background_image_desktop,
    background_image_mobile,
    primary_button_url,
    primary_button_label,
    secondary_button_url,
    secondary_button_label,
    banner_title_font_size,
    banner_title_font_family,
    desktop_container_height,
    desktop_container_width,
    overlay_image_desktop,
    overlay_image_mobile,
    overlay_image_height,
    image_desktop_fit,
    analyticsTrackingData,
    isMobileDevice,
    image_href,
  } = props

  const isMobile = useBreakpointValue({ base: true, lg: false })
  const custom_title_color = title_font_color === 'Custom' ? select_custom_title_font_color : null
  const _theme = isMobile ? 'dark' : theme

  const links = [
    { label: props.link_label_1, href: props.link_url_1 },
    { label: props.link_label_2, href: props.link_url_2 },
    { label: props.link_label_3, href: props.link_url_3 },
    { label: props.link_label_4, href: props.link_url_4 },
    { label: props.link_label_5, href: props.link_url_5 },
    { label: props.link_label_6, href: props.link_url_6 },
  ]

  const primaryButtonProps = {
    children: primary_button_label,
    variant: 'solid',
    href: primary_button_url,
    backgroundColor: _theme === 'light' ? 'white' : 'text',
    textColor: _theme === 'light' ? 'text' : 'white',
    size: 'lg',
  }

  const secondaryButtonProps = {
    children: secondary_button_label,
    variant: 'outline',
    href: secondary_button_url,
    backgroundColor: 'none',
    textColor: _theme === 'light' ? 'white' : 'text',
    borderColor: _theme === 'light' ? 'white' : 'none',
    size: 'lg',
  }

  const bannerLinksProps = links
    ?.filter((el) => el.label)
    ?.map((el) => {
      return {
        children: el.label,
        variant: 'outline',
        href: el.href ?? '',
        backgroundColor: 'none',
        textColor: _theme === 'light' ? 'white' : 'text',
        borderColor: _theme === 'light' ? 'white' : 'none',
        size: 'lg',
      }
    })

  return (
    <BannerFull
      isMobileDevice={isMobileDevice}
      theme={_theme as BannerFullProps['theme']}
      customTitleColor={custom_title_color}
      displayOpacity={display_opacity_overlay}
      desktopContainerHeight={desktop_container_height}
      desktop_container_width={desktop_container_width}
      overlayImageDesktop={overlay_image_desktop}
      overlayImageMobile={overlay_image_mobile}
      overlayImageHeight={overlay_image_height}
      textPosition={content_alignment as BannerFullProps['textPosition']}
      contentPosition={content_positioning as BannerFullProps['contentPosition']}
      image={{
        href: image_href ?? undefined,
        imageDesktop: {
          src: imageOptimization(background_image_desktop.url),
          alt: background_image_desktop?.description || background_image_desktop?.title || banner_title,
          objectFit: (image_desktop_fit as ObjectFit) ?? 'cover',
        },
        imageMobile: {
          src: imageOptimization(background_image_mobile?.url),
          alt: background_image_mobile?.description || background_image_mobile?.title || banner_title,
        },
      }}
      text={{
        eyebrow: {
          children: eyebrow,
        },
        title: {
          font: {
            size: banner_title_font_size ?? '',
            family: banner_title_font_family ?? '',
          },
          content: {
            children: banner_title,
          },
        },
        body: {
          children: content ? <Box dangerouslySetInnerHTML={{ __html: content }} /> : undefined,
        },
        ctaButtonPrimary: primaryButtonProps,
        ctaButtonSecondary: secondaryButtonProps,
        ctaLinkItems: bannerLinksProps,
      }}
      analyticsTrackingData={analyticsTrackingData}
    />
  )
}
