export const renderOption = {
  customElementTypes: {
    hr: () => {
      return `<div data-type='hr' style='border-top: 1px solid #E7EAED; margin:56px 0; ' ></div>`
    },

    reference: (attrs: any, child: any, jsonBlock: any, extraAttrs: any) => {
      let result

      switch (extraAttrs?.displayType) {
        case 'inline':
          result = `<span${attrs}>${child}</span>`
          break
        case 'block':
          result = `<div${attrs}>${child}</div>`
          break
        case 'link':
          result = `<a${attrs}>${child}</a>`
          break
        case 'asset':
          result = `<figure${attrs}>${child}</figure>`
          break

        case 'display':
          const alignment = jsonBlock.attrs?.['redactor-attributes']?.position || 'none'
          const isInline = jsonBlock.attrs?.inline
          const padding = isInline ? '16px' : '0'
          const paddingStyle = isInline
            ? `padding-top: ${padding}; padding-bottom: ${padding}; padding-${
                alignment === 'left' ? 'right' : 'left'
              }: ${padding};`
            : ''

          const floatStyle = isInline ? `float: ${alignment};` : ''
          const style = `width: ${jsonBlock.attrs?.width || 'auto'};
                   height: ${jsonBlock.attrs?.height || 'auto'};
                   ${floatStyle}
                   ${paddingStyle}`

          const caption = jsonBlock.attrs?.['asset-caption']
            ? `<figcaption style="text-align: ${alignment};">${jsonBlock.attrs['asset-caption']}</figcaption>`
            : ''

          return `<figure
                    class="content-image"
                    style="${style}"
                  >
                    <img
                       src="${jsonBlock.attrs?.['asset-link']}"
                       alt="${jsonBlock.attrs?.['asset-alt']}"
                       style="height: auto; ${isInline ? '' : 'width: 100%;'}"
                    >
                    ${caption}
                  </figure>`
        default:
          result = `<span${attrs}>${child}</span>`
          break
      }
      return result
    },
  },
}
