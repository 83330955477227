import React from 'react'
import { jsonToHtml } from '@contentstack/json-rte-serializer'
import { ContentstackAccordion as ContentstackAccordionProps } from '@Types/contentstack'
import { CmsAccordion } from '../cms-components/cms-accordion'
import { ComponentRichText } from '../cms-components/rich-text'
import { renderOption } from '../cms-components/RichText-Utils/render-options'

export const ContentstackAccordion = (props: ContentstackAccordionProps) => {
  const { accordion_title, content, initial_state_is_open, accordion_title_font_size, accordion_title_font_family } =
    props

  return (
    <CmsAccordion
      text={{
        title: {
          font: {
            size: accordion_title_font_size ?? '',
            family: accordion_title_font_family ?? '',
          },
          content: {
            children: accordion_title,
          },
        },
        body: {
          children: content ? <ComponentRichText>{jsonToHtml(content, renderOption)}</ComponentRichText> : undefined,
        },
      }}
      initialStateIsOpen={initial_state_is_open}
    />
  )
}
