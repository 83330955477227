import { useState, useEffect } from 'react'
import { Skeleton } from '@chakra-ui/react'
import {
  ContentstackTeamLogoGrid as ContentstackTeamLogoGridProps,
  ContentstackTeamLogoCard,
} from '@Types/contentstack'
import { TeamLogoGrid } from '../cms-components/team-logo-grid'
import useContentstack from '../hooks/useContentstack'

export const ContentstackTeamLogoGrid = ({
  children: items,
  container_margin_bottom,
  container_margin_top,
  container_size,
  container_background_color,
  container_column_number,
  container_content_alignment,
  container_column_width,
  container_eyebrow,
  container_title,
  container_description,
  heading_alignment,
  title_font_family,
  title_font_size,
}: ContentstackTeamLogoGridProps) => {
  const [contentList, setContentList] = useState<ContentstackTeamLogoCard[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    const getGridContent = async (items: ContentstackTeamLogoCard[]) => {
      try {
        const itemsResults = items?.map((item) => getEntryByUid('component_team_logo_card', item.uid))
        const results = (await Promise.all(itemsResults)) as unknown as ContentstackTeamLogoCard[]

        setContentList(results)
      } catch (err) {
        console.error('Error processing Team Logo Grid data', err)
      }
    }

    getGridContent(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <Skeleton />

  return (
    <TeamLogoGrid
      items={contentList}
      containerMarginBottom={container_margin_bottom}
      containerMarginTop={container_margin_top}
      containerSize={container_size}
      backgroundColor={container_background_color}
      containerColumnsNumber={container_column_number}
      containerContentAlignment={container_content_alignment}
      containerColumnWidth={container_column_width}
      containerEyebrow={container_eyebrow}
      containerDescription={container_description}
      containerTitle={container_title}
      headingAlignment={heading_alignment}
      titleFontFamily={title_font_family}
      titleFontSize={title_font_size}
    />
  )
}
