import { Box, useBreakpointValue, Image } from '@chakra-ui/react'
import { BannerTextOnlyTheme, ContentstackImage } from '@Types/contentstack'
import { BannerText, BannerTextProps } from './banner-text'
import { BaseProps } from './types'

export interface BannerTextOnlyProps extends BaseProps {
  text?: BannerTextProps
  content_alignment?: string | null
  theme?: string
  select_custom_background_color?: string | null
  select_custom_title_font_color?: string | null
  select_custom_text_color?: string | null
  overlay_image_mobile?: ContentstackImage | null
  overlay_image_desktop?: ContentstackImage | null
  overlay_image_height?: string | null
}

export const BannerTextOnly = ({
  text,
  content_alignment,
  theme = 'Light',
  select_custom_background_color,
  select_custom_title_font_color,
  select_custom_text_color,
  overlay_image_mobile,
  overlay_image_desktop,
  overlay_image_height,
  isMobileDevice,
}: BannerTextOnlyProps) => {
  const background = customColors('background', theme, select_custom_background_color)
  const textColor = customColors('text', theme, select_custom_text_color)
  const titleTextColor = customColors('title', theme, select_custom_title_font_color)

  const { font: titleFont } = text?.title ?? {}
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  return (
    <Box display="flex" flexWrap="wrap" alignItems="stretch" backgroundColor={background}>
      <BannerText
        isMobileDevice={isMobileDevice}
        root={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: content_alignment === 'Center' ? 'center' : 'left',
          px: isMobile ? '40px' : '64px',
          py: isMobile ? '40px' : '64px',
          width: '100%',
          ...text?.root,
        }}
        eyebrow={{
          textStyle: ['eyebrow-50', null, 'eyebrow-75'],
          textColor: [textColor, null, textColor],
          ...text?.eyebrow,
        }}
        title={{
          font: titleFont,
          content: {
            textColor: [titleTextColor, null, titleTextColor],
            ...text?.title?.content,
          },
        }}
        body={{
          textStyle: ['callouts-blockquote-75-Default', null, 'body-200'],
          textColor: [textColor, null, textColor],
          ...text?.body,
        }}
        ctaButtonPrimary={{ ...text?.ctaButtonPrimary, backgroundColor: textColor }} // backgroundColor: textColor ensures that btn color switches with change in content-color
        ctaButtonSecondary={{
          ...text?.ctaButtonSecondary,
          textColor: textColor,
          borderColor: textColor,
          backgroundColor: 'none',
        }} // textColor: textColor, borderColor: textColor ensures that btn outline props changes as content-color
        ctaButtonBox={{
          justifyContent: content_alignment === 'Center' ? 'center' : 'left',
          ...text?.ctaButtonBox,
        }}
        content_alignment={content_alignment}
        overlay_image_mobile={overlay_image_mobile}
        overlay_image_desktop={overlay_image_desktop}
        overlay_image_height={overlay_image_height}
      />
    </Box>
  )
}

const backgroundValue: Record<BannerTextOnlyTheme, string> = {
  Light: 'surface',
  Dark: 'surface.inverse',
}

const textColorValue: Record<BannerTextOnlyTheme, string> = {
  Light: 'text',
  Dark: 'white',
}

const customColors = (type: 'background' | 'text' | 'title', theme: string, selectCustomColor: string) => {
  let returnValue: string = ''

  if (type === 'text') {
    if (selectCustomColor) {
      returnValue = selectCustomColor
    } else {
      returnValue = textColorValue[theme]
    }
  }

  if (type === 'title') {
    if (selectCustomColor) {
      returnValue = selectCustomColor
    } else {
      returnValue = textColorValue[theme]
    }
  }

  if (type === 'background') {
    if (selectCustomColor) {
      returnValue = selectCustomColor
    } else {
      returnValue = backgroundValue[theme]
    }
  }

  return returnValue
}
